<template>
  <v-col cols="12" sm="9">
    <h2 class="mb-3">Forgot your password?</h2>
    <h4>We'll email your instructions on how to reset it</h4>
    <div v-if="isSendEmail" class="noti my-5">
      <span style="color: black"
        >Instructions to reset your password have beeen emailed to you. Not receive email yet?</span
      >
      <span @click="onForgot" class="ml-2 pointer resend"><strong>Resend</strong></span>
    </div>

    <div class="mt-6" v-if="!isSendEmail">
      <span class="label-form"> Email </span>
      <v-text-field
        placeholder="example@gmail.com"
        autocomplete="off"
        name="email"
        id="email"
        v-model.trim="$v.email.$model"
        :error-messages="
          invalid && !$v.email.required
            ? $t('Email is required')
            : invalid && !$v.email.email
            ? $t('Invalid Email')
            : invalid && !$v.email.minLength
            ? $t('Email must have at least ' + $v.email.$params.minLength.min + ' letters.')
            : invalid && !$v.email.maxLength
            ? $t('Email must have at least ' + $v.email.$params.maxLength.max + ' letters.')
            : ''
        "
      ></v-text-field>
    </div>

    <div class="mb-5" v-if="!isSendEmail">
      <v-btn @click="onForgot" :loading="isLoading" width="100%" color="primary">Email instructions</v-btn>
    </div>
    <div class="label-forgot">
      <span @click="onReturn">Return to Sign in?</span>
    </div>
  </v-col>
</template>

<script>
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators';
import { authService } from '@/apis/auth.s';

export default {
  data() {
    return {
      isLoading: false,
      email: '',
      invalid: false,
      isSendEmail: false,
    };
  },
  validations: {
    email: {
      required,
      email,
      minLength: minLength(4),
      maxLength: maxLength(50),
    },
  },
  methods: {
    onReturn() {
      this.isSendEmail = false;
      this.$router.push({ path: '/auth/login' });
    },
    async onForgot() {
      this.isLoading = true;
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      try {
        if (!this.$v.$invalid) {
          //empty
          await authService.sendForgotEmail(this.email.toLowerCase());
          console.log(this.$router);
          // let a = await authService.getToken();
          // console.log(a);
          this.isSendEmail = true;
        }
        setTimeout(() => {
          this.isLoading = false;
        }, 500);
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h1 {
  font-size: 2.2rem;
}
h4 {
  font-size: 1rem;
  color: #82869e !important;
}
.noti {
  padding: 15px;
  width: 280px;
  background-color: #80ce0945;
  border-left: 2px solid $main-color;
}
.resend:hover {
  text-decoration: underline;
}
</style>
