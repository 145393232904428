<template>
  <div class="d-flex justify-center auth-pages" style="font-size : 0.8rem !important">
    <v-row no-gutters class="">
      <v-col no-gutters cols="12" md="4" sm="12" class="login-form">
        <div class="auth-container">
          <!-- Login page -->
          <Login v-if="!authPage || authPage === 'login'" />
          <!-- Register page -->
          <Register v-if="authPage === 'register'" />
          <!-- Forgot password page -->
          <Forgot v-if="authPage === 'forgot'" />
          <Reset @setIsReset="setReset" v-if="authPage === 'reset-pwd-action'" />
          <!-- //shop && token -->
          <Shop v-if="authPage === 'shop'" />
          <CreateStaff v-if="authPage === 'accept'" />
          <VerifyEmail v-if="authPage === 'verify'" />
          <Reseted v-if="authPage === 'reseted'" />
        </div>
      </v-col>
      <v-col class="right-content d-none d-sm-flex" cols="12" md="8" sm="12">
        <v-parallax style="height: 100%;width: 100%;" :src="require('@/assets/images/bg-login.png')"></v-parallax>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Login from './components/Login';
import Register from './components/Register';
import Forgot from './components/Forgot';
import Shop from './components/Shop';
import Reseted from './components/ResetedPw';
import Reset from './components/ResetPassword';
import STORAGE_NAME from '@/const/storage';
import { userService } from '@/apis/user.s';
import { mapMutations } from 'vuex';
import { CreateStaff, VerifyEmail } from './components/email-account';
export default {
  data() {
    return {
      loading: false,
    };
  },
  props: ['authPage'],
  components: {
    Reseted,
    Reset,
    Login,
    Register,
    Forgot,
    Shop,
    CreateStaff,
    VerifyEmail,
  },
  async mounted() {
    let TOKEN = localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
    let REFRESH_TOKEN = localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN);
    let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
    if (TOKEN && this.$route.params.authPage !== 'accept' && this.$route.params.authPage !== 'verify') {
      this.$router.push({ name: 'auth', params: { authPage: 'shop' } });
    }
  },
  beforeCreate() {
    if (this.$route.query.l) {
      localStorage.clear();
      this.$router.replace({ query: null });
    }
  },

  methods: {
    setReset(a) {
      this.loading = true;
      setTimeout(() => (this.loading = false), 2000);
    },
  },
  created() {
    if (this.$route.query?.code) {
      this.$store.commit('setCodeRefCreateStore', this.$route.query.code);
    }
    if (this.$store.state.auth.codeRefCreateStore) {
      this.$router.push({ query: { ...this.$route.query, code: this.$store.state.auth.codeRefCreateStore } });
    }
  },
  watch: {
    $route() {
      if (this.$store.state.auth.codeRefCreateStore) {
        this.$router.push({ query: { ...this.$route.query, code: this.$store.state.auth.codeRefCreateStore } });
      }
    },
  },
};
</script>

<style lang="scss">
.auth-pages {
  width: 100%;
  height: 100%;
  .auth-container {
    @include flex();
    padding: 10px;
  }
  .right-content {
    height: 100%;
    @include flex();
    background: #131f37;
    color: white;
  }
  .v-parallax__image {
    transform: translate(-50%) !important;
  }
}
.login-form {
  padding: 10rem 2rem;
  @include flex();
}
.login-form h1 {
  font-size: 1.5rem;
}
@media only screen and (min-width: 601px) {
  .auth-container {
    @include flex();
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .login-form {
    width: 100% !important;
  }
  .auth-container {
    @include flex();
    width: 100%;
  }
}
</style>
