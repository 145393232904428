var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('h1',{},[_vm._v("Sign up")]),(_vm.errorMessage.length > 0)?_c('Notification',{attrs:{"messages":_vm.errorMessage,"type":"error"}}):_vm._e(),_c('div',[_c('span',{staticClass:"label-form"},[_vm._v(" Email ")]),_c('v-text-field',{attrs:{"autocomplete":"off","name":"email","id":"email","error-messages":_vm.invalid && !_vm.$v.email.required
          ? _vm.$t('Field is required')
          : _vm.invalid && !_vm.$v.email.email
          ? _vm.$t('Invalid Email')
          : _vm.invalid && !_vm.$v.email.minLength
          ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.minLength.min + ' letters.')
          : _vm.invalid && !_vm.$v.email.maxLength
          ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.maxLength.max + ' letters.')
          : ''},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1),_c('div',[_c('span',{staticClass:"label-form"},[_vm._v(" Password ")]),_c('v-text-field',{attrs:{"type":_vm.type,"autocomplete":"off","name":"password","id":"password","error-messages":_vm.invalid && !_vm.$v.password.required
          ? _vm.$t('Password is required')
          : _vm.invalid && !_vm.$v.password.minLength
          ? _vm.$t('Password must have at least ' + _vm.$v.password.$params.minLength.min + ' letters.')
          : _vm.invalid && !_vm.$v.password.maxLength
          ? _vm.$t('Password must have at least ' + _vm.$v.password.$params.maxLength.max + ' letters.')
          : ''},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.password.$model"}},[_c('template',{slot:"append"},[_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'text'),expression:"type === 'text'"}],staticClass:"show-password far fa-eye-slash",on:{"click":function($event){_vm.type = _vm.type === 'text' ? 'password' : 'text'}}}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.type === 'password'),expression:"type === 'password'"}],staticClass:"show-password far fa-eye",on:{"click":function($event){_vm.type = _vm.type === 'text' ? 'password' : 'text'}}})])],2)],1),_c('div',[_c('span',{staticClass:"label-form"},[_vm._v(" Shop name ")]),_c('v-text-field',{attrs:{"autocomplete":"off","name":"shop_name","id":"shop_name","error-messages":_vm.invalid && !_vm.$v.shopName.required
          ? _vm.$t('Shop name is required')
          : _vm.invalid && !_vm.$v.shopName.minLength
          ? _vm.$t('Shop name must have at least ' + _vm.$v.shopName.$params.minLength.min + ' letters.')
          : _vm.invalid && !_vm.$v.shopName.maxLength
          ? _vm.$t('Shop name must have at least ' + _vm.$v.shopName.$params.maxLength.max + ' letters.')
          : _vm.invalid && !_vm.$v.shopName.alpha
          ? _vm.$t('Shop name cannot contain special characters')
          : ''},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSignup($event)}},model:{value:(_vm.$v.shopName.$model),callback:function ($$v) {_vm.$set(_vm.$v.shopName, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.shopName.$model"}})],1),_c('div',{staticClass:"mt-5"},[_c('v-btn',{attrs:{"loading":_vm.isLoading,"width":"100%","color":"primary"},on:{"click":_vm.onSignup}},[_vm._v(" Sign up ")])],1),_c('div',{staticClass:"label-forgot mt-5"},[_c('span',{on:{"click":function($event){return _vm.$router.push({ path: '/auth/login' })}}},[_vm._v("Already have an account? Sign in")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }