<template>
  <div style="width: 100%; height: 100vh" v-if="isLoading" class="d-flex justify-center align-center">
    <page-loader />
  </div>
  <v-col cols="12" sm="8" v-else>
    <h2 class="d-none d-sm-flex mb-3">Select a shop</h2>
    <div>
      <!-- User detail -->
      <v-card v-if="user" :ripple="false" class="portrait" @click="show">
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="4">
              <v-avatar color="primary">
                <text-avatar :text="fullName" />
              </v-avatar>
            </v-col>
            <v-col cols="7">
              <h3>{{ fullName }}</h3>
              <span>{{ user.email }}</span>
            </v-col>
            <v-col cols="1" class="d-flex align-center">
              <i style="margin-left: auto" class="fas fa-chevron-down pr-4 pointer"></i>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-menu v-model="showMenu" :position-x="x" :position-y="y" absolute offset-y>
        <v-list>
          <v-list-item>
            <v-list-item-title> Partner dashboard </v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout">
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- Add a new shop -->
    <div class="mt-5">
      <v-btn v-if="!showInput" width="100%" @click="showInput = !showInput" color="primary">Add a new shop</v-btn>
      <div v-else class="d-flex add-new-shop-container mt-3">
        <v-text-field
          v-model="store.name"
          :error-messages="
            invalid && !$v.store.name.required
              ? $t('Shop name is required')
              : invalid && !$v.store.name.minLength
              ? $t('Shop name must have at least ' + $v.store.name.$params.minLength.min + ' letters.')
              : invalid && !$v.store.name.maxLength
              ? $t('Shop name must have at least ' + $v.store.name.$params.maxLength.max + ' letters.')
              : invalid && !$v.store.name.alpha
              ? $t('Shop name cannot contain special characters')
              : error
              ? $t(error)
              : ''
          "
          class="mr-3"
          placeholder="Your shop name"
        ></v-text-field>
        <v-btn :loading="isLoadingButton" @click="addStore" color="primary">Save</v-btn>
      </div>
    </div>
    <!-- List store -->
    <div class="select-shop-page mt-5 pa-1" style="max-height: 600px; overflow-x: auto">
      <v-card
        v-for="(store, index) in stores"
        :key="index"
        @click="onClickShop(store)"
        class="mt-3 mx-auto px-3 py-3 d-flex align-center"
        tile
      >
        <div class="p-3">
          <p>{{ store.storeDetail.name }}</p>
          <p>
            <span> {{ store.storeDetail.accountEmail }} </span>
          </p>
          <span> {{ store.storeDetail.defaultSystemDomain }} </span>
        </div>
        <i style="margin-left: auto" class="fas fa-chevron-right pr-4 pointer"></i>
      </v-card>
    </div>
  </v-col>
</template>

<script>
import { authService } from '@/apis/auth.s';
import { userService } from '@/apis/user.s';
import { storeService } from '@/apis/store.s';
import STORAGE_NAME from '@/const/storage';
import { mapGetters, mapMutations } from 'vuex';
import TextAvatar from '@/components/TextAvatar';
import { required, minLength, maxLength, email, helpers } from 'vuelidate/lib/validators';
const alpha = helpers.regex('alpha', /^[a-zA-Z0-9]*$/);
import verifyMixin from '@/mixins/verify';
import { widgetApi } from '@/apis/widget';
import accountRequest from '@/apis/request/accountRequest';

export default {
  components: {
    TextAvatar,
  },
  mixins: [verifyMixin],
  validations: {
    store: {
      name: {
        required,
        alpha,
        minLength: minLength(4),
        maxLength: maxLength(50),
      },
    },
  },
  data() {
    let defaultConfig = {
      activated: false,
      device: 'desktop',
      widgetType: 'bestSeller',
      widgetCustomize: {
        productPrice: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Title ',
        },
        productName: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '14px',
          italic: false,
          underline: false,
          textTitle: 'Programmer Label Personalized',
        },
        place: {
          cartPage: false,
          categoryPage: false,
          homePage: false,
          productPage: true,
          page: {
            collectionIds: [],
            productIds: [],
            selectType: 'products',
            isShowAtSpecificPage: false,
          },
        },
        header: {
          align: 'center',
          bold: false,
          fontColor: '#000000',
          fontSize: '25px',
          italic: false,
          underline: false,
          textTitle: 'Handpicked products just for you',
        },

        addCartButton: {
          enabled: true,
          callToAction: {
            align: 'center',
            bold: false,
            fontColor: '#000000',
            fontSize: '12px',
            italic: false,
            underline: false,
            textTitle: 'Title ',
          },
        },
        layout: '1',
        numberOfProducts: {
          productsPerSlide: 6,
          productsToBeShown: 12,
        },
      },
    };
    return {
      defaultConfig,
      type: 'password',
      isLoading: false,
      isLoadingButton: false,
      stores: [],
      showMenu: false,
      x: 0,
      y: 0,
      invalid: false,
      showInput: false,
      store: {
        name: '',
      },
      error: null,
    };
  },
  async created() {
    this.isLoading = true;
    await this.auth();
    await this.getUserInfo();
    await this.getStores();
    this.isLoading = false;
  },
  methods: {
    ...mapMutations(['setUser']),
    async createAllAfterCreateStore(storeId, defaultSystemDomain) {
      let [cartRecommend, moreCollection, bestSeller, recentView, alsoBought, handpickedProduct, pickForYou] = [
        {
          ...this.defaultConfig,
          widgetType: 'cartRecommend',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Cart recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'moreCollection',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'More from {{collection_name}}',
            },
            place: {
              cartPage: true,
              homePage: false,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'bestSeller',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Store best sellers',
            },
            place: {
              homePage: true,
              cartPage: false,
              productPage: false,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'recentView',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Recently viewed & featured recommendations',
            },
            place: {
              cartPage: true,
              homePage: true,
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'alsoBought',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Who bought this also bought',
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'handpickedProduct',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
        {
          ...this.defaultConfig,
          widgetType: 'pickForYou',
          activated: false,
          widgetCustomize: {
            ...this.defaultConfig.widgetCustomize,
            header: {
              ...this.defaultConfig.widgetCustomize.header,
              textTitle: 'Pick For You',
            },
            showWith: 'sameTitle',
            place: {
              productPage: true,
              page: {
                collectionIds: [],
                productIds: [],
                selectType: 'products',
                isShowAtSpecificPage: false,
              },
            },
          },
        },
      ];
      //cartRecommend

      let res = await widgetApi.createAllAfterCreateStore(
        {
          productWidgets: [
            cartRecommend,
            moreCollection,
            bestSeller,
            recentView,
            alsoBought,
            handpickedProduct,
            pickForYou,
          ],
        },
        storeId,
        defaultSystemDomain,
      );
    },
    async getUserInfo() {
      try {
        let data = await userService.get();
        if (data) {
          this.setUser(data.data);
          localStorage.removeItem('user');
          localStorage.setItem('user', JSON.stringify(data.data));
        }
      } catch (error) {
        console.log(error);
      }
    },
    /*
    Func show dropdown
    */
    show(e) {
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    /*
    Fnc click and redirect to admin
    */
    async onClickShop(store) {
      window.localStorage.setItem(STORAGE_NAME.STORE_ID, store.id);
      window.localStorage.setItem(STORAGE_NAME.STORE_DOMAIN, store.storeDetail.defaultSystemDomain);
      let access_token = window.localStorage.getItem(STORAGE_NAME.ACCESS_TOKEN);
      let refresh_token = window.localStorage.getItem(STORAGE_NAME.REFRESH_TOKEN);
      // localStorage.clear();
      try {
        let data = await authService.checkSurvey({
          storeId: store.id,
          token: access_token,
        });
        console.log('🚀 ~ file: Shop.vue:406 ~ onClickShop ~ data:', data);
        if (data.status == 200) {
          setTimeout(() => {
            let baseUrl = store.storeDetail.primaryDomain || store.storeDetail.defaultSystemDomain;
            let url = 'https://' + baseUrl + '/admin/';
            if (window.location.href.includes('localhost')) {
              // For deverlopment
              url = `${window.location.origin}/admin/`;
            }

            window.location.href =
              url +
              '?access_token=' +
              access_token +
              '&refresh_token=' +
              refresh_token +
              '&system_domain=' +
              store.storeDetail.defaultSystemDomain +
              '&store_id=' +
              store.id +
              '&r_domain=' +
              (store.primaryDomain ? store.primaryDomain : store.storeDetail.defaultSystemDomain);
          }, 0);
        }
      } catch (error) {
        localStorage.setItem('defaultSystemDomain', store.storeDetail.defaultSystemDomain);
        this.$router.push({
          name: 'auth/survey',
          query: {
            register: store.storeDetail.name,
            storeId: store.id,
            defaultSystemDomain: store.storeDetail.defaultSystemDomain,
          },
        });
        console.log('🚀 ~ file: Shop.vue:408 ~ onClickShop ~ error:', error);
      }
    },
    /*
    Fnc get user stores
    */
    async getStores() {
      try {
        let data = await userService.getStores();
        this.stores = data.data.reverse().filter(store => store !== null);
      } catch (error) {
        console.log(error.response);
      }
    },
    /*
    Fnc logout
    */
    logout() {
      try {
        authService.logout();
        localStorage.clear();
        this.$router.push({ name: 'home' });
      } catch (error) {
        console.log(error.response);
      }
    },
    /*
    Fnc add store
    */
    async addStore() {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      this.isLoadingButton = true;
      if (!this.$v.$invalid) {
        try {
          const codeRef = this.$store.state?.auth.codeRefCreateStore;
          let newStore = await userService.addStore(codeRef ? { ...this.store, code: codeRef } : this.store);
          await this.getStores();
          let storeId = newStore ? newStore.data.storeId : '';
          let findStore = this.stores.find(s => s.id === storeId);
          let defaultSystemDomain = findStore ? findStore.storeDetail.defaultSystemDomain : '';
          localStorage.setItem('store-id', storeId);
          localStorage.setItem('defaultSystemDomain', defaultSystemDomain);
          this.createAllAfterCreateStore(storeId, defaultSystemDomain);
          this.$store.commit('setMessages', {
            messages: 'Create store successfully!',
            type: 'success',
          });
          this.$router.push({
            name: 'auth/survey',
            query: { register: this.store.name, storeId: storeId, defaultSystemDomain: defaultSystemDomain },
          });
          this.store.name = '';
          // this.createAllWidget();
          this.$v.$reset();
          this.invalid = false;
          this.error = null;
          this.isLoadingButton = false;
        } catch (error) {
          this.error = error;
          this.isLoadingButton = false;
        }
      } else {
        this.isLoadingButton = false;
      }
    },
  },
  computed: {
    ...mapGetters(['user']),
    fullName: function() {
      return (this.user.firstName ? this.user.firstName : '') + ' ' + (this.user.lastName ? this.user.lastName : '');
    },
  },
};
</script>
<style lang="scss">
.show-password {
  cursor: pointer !important;
  margin-right: 5px;
  margin-top: 5px;
}
.add-new-shop-container {
  padding: 10px;
  background-color: #f8f8fc;
  border-radius: 8px;
}
.label-forgot span {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .login-form {
    width: 100% !important;
  }
}
</style>
