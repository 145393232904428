<template>
  <v-col sm="9" class="mx-auto">
    <h2 class="mb-5">Reset Your Password</h2>
    <v-row no-gutters>
      <v-col sm="12">
        <label class="label-form" for="">New password</label>
        <v-text-field
          type="password"
          hide-details="auto"
          v-model.trim="$v.newPassword.$model"
          :error-messages="
            invalid && !$v.newPassword.required
              ? $t('Password is required')
              : invalid && !$v.newPassword.minLength
              ? $t('Password must have at least ' + $v.newPassword.$params.minLength.min + ' characters')
              : ''
          "
        ></v-text-field>
      </v-col>
      <!-- <v-col class="mb-4">Your password must be at least 5 characters, and cant't begin or end with a space</v-col> -->
      <v-col sm="12" class="mt-5">
        <label class="label-form" for="">Confirm password</label>
        <v-text-field
          type="password"
          v-model.trim="confirmPassword"
          hide-details="auto"
          :error-messages="
            invalid && !$v.confirmPassword.sameAsPassword
              ? $t('Your password and confirmation password do not match')
              : ''
          "
        ></v-text-field>
      </v-col>
      <v-col sm="12" class="text-center mt-5"
        ><v-btn color="primary" class="text-center" @click="reset">Reset password</v-btn></v-col
      >
      <v-col sm="12" class="text-center mt-3 pointer"><div @click="cancel">Cancel</div></v-col>
    </v-row>
  </v-col>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { authService } from '@/apis/auth.s';

export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      invalid: false,
      tokenn: '',
      clicked: false,
    };
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(5),
    },
    confirmPassword: {
      sameAsPassword: sameAs('newPassword'),
    },
  },
  created() {
    this.getTokenn();
  },
  methods: {
    async getTokenn() {
      try {
        this.tokenn = this.$route.query.token;
        console.log(this.tokenn);
        // để check xem đã reset pass lần nào chưa, nếu ở try thì chưa
        let b = await authService.getToken(this.$route.query.token);
      } catch (error) {
        // còn đã reset rồi thì api trả về 406
        this.$router.push({ path: '/auth/reseted' });
      }
    },
    cancel() {
      // do  sử dụng Passing Props to Route Components nên push path chứ k push name
      this.$router.push({ path: '/auth/login' });
    },
    async reset() {
      this.$v.$touch();
      this.invalid = this.$v.$invalid;
      if (!this.invalid && this.confirmPassword == this.newPassword) {
        // dosomething
        let params = {};
        params.password = this.newPassword;
        params.token = this.tokenn;
        await authService.resetPw(params);
        this.$router.push({ path: '/auth/login' });
      }
    },
  },
};
</script>

<style></style>
