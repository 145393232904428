<template>
  <div>
    <h1>The page youʼre looking for canʼt be found</h1>
    <div class="text-center">
      <v-btn @click="back">Back to Lattehub admin</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    back() {
      // this.isResetPw = true;
      this.$router.push({ name: 'auth', params: { authPage: 'login' } });
    },
  },
};
</script>

<style></style>
