var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":"12","sm":"9"}},[_c('h2',{staticClass:"mb-3"},[_vm._v("Forgot your password?")]),_c('h4',[_vm._v("We'll email your instructions on how to reset it")]),(_vm.isSendEmail)?_c('div',{staticClass:"noti my-5"},[_c('span',{staticStyle:{"color":"black"}},[_vm._v("Instructions to reset your password have beeen emailed to you. Not receive email yet?")]),_c('span',{staticClass:"ml-2 pointer resend",on:{"click":_vm.onForgot}},[_c('strong',[_vm._v("Resend")])])]):_vm._e(),(!_vm.isSendEmail)?_c('div',{staticClass:"mt-6"},[_c('span',{staticClass:"label-form"},[_vm._v(" Email ")]),_c('v-text-field',{attrs:{"placeholder":"example@gmail.com","autocomplete":"off","name":"email","id":"email","error-messages":_vm.invalid && !_vm.$v.email.required
          ? _vm.$t('Email is required')
          : _vm.invalid && !_vm.$v.email.email
          ? _vm.$t('Invalid Email')
          : _vm.invalid && !_vm.$v.email.minLength
          ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.minLength.min + ' letters.')
          : _vm.invalid && !_vm.$v.email.maxLength
          ? _vm.$t('Email must have at least ' + _vm.$v.email.$params.maxLength.max + ' letters.')
          : ''},model:{value:(_vm.$v.email.$model),callback:function ($$v) {_vm.$set(_vm.$v.email, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.email.$model"}})],1):_vm._e(),(!_vm.isSendEmail)?_c('div',{staticClass:"mb-5"},[_c('v-btn',{attrs:{"loading":_vm.isLoading,"width":"100%","color":"primary"},on:{"click":_vm.onForgot}},[_vm._v("Email instructions")])],1):_vm._e(),_c('div',{staticClass:"label-forgot"},[_c('span',{on:{"click":_vm.onReturn}},[_vm._v("Return to Sign in?")])])])}
var staticRenderFns = []

export { render, staticRenderFns }